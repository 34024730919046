import React, { useState, useEffect } from 'react';
export const FITBIT_API_SEP = "---"


function App() {
  const [queryParams, setQueryParams] = useState(null);

  const [message, setMessage] = useState(() => "")

  useEffect(() => {
    // Function to parse query parameters from URL
    const getQueryParams = () => {
      const hash = window.location.hash.substr(1);
      const searchParams = new URLSearchParams(hash);
      const params = {};
      for (let [key, value] of searchParams.entries()) {
        params[key] = value;
      }
      return params;
    };

    // Update state with query parameters when component mounts
    setQueryParams(getQueryParams());
  }, []);

  // Function to copy value to clipboard
  const copyToClipboard = (name, value) => {
    navigator.clipboard.writeText(value);
    setMessage(`Copied ${name} to clipboard`)
  };

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>Response Values</h1>
      <button onClick={() => copyToClipboard("Both Values", queryParams['access_token'] + FITBIT_API_SEP + queryParams['user_id'])}>Copy Both Values</button>

      {queryParams ? (
        <>
          <div style={{ marginBottom: "50px" }}>
            <h4>Access Token</h4>
            <div style={{ width: "80%", marginLeft: "10%", marginRight: "10%", overflowWrap: "anywhere", marginBottom: "10px" }}>
              {queryParams['access_token']}
            </div>
            <button onClick={() => copyToClipboard("Access Token", queryParams['access_token'])}>Copy</button>
          </div>
          <div style={{ marginBottom: "50px" }}>
            <h4>Request ID</h4>
            <div style={{ width: "40%", marginLeft: "30%", marginRight: "30%", overflowWrap: "anywhere", marginBottom: "10px" }}>
              {queryParams['user_id']}
            </div>
            <button onClick={() => copyToClipboard("Request ID", queryParams['user_id'])}>Copy</button>
          </div>

          <div>
            {message}
          </div>

        </>
      ) : (
        <p>No query parameters found.</p>
      )}
    </div>
  );
}

export default App;
